import { useState, useEffect } from "react";
import Image from "next/image";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ResponsiveImage({ mobile, desktop }) {
  const { isSmallDevice } = useWindowDimensions();
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(window.innerWidth > 768);
  }, [isSmallDevice]);

  return (
    <>
      {isDesktop ? (
        <Image alt="Image" {...desktop} />
      ) : (
        <Image alt="Image" {...mobile} />
      )}
    </>
  );
}
