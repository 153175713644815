export default [
  {
    id: 1,
    name: "Sheilah S",
    date: "9/07/2022",
    location: "Phoenix",
    shortSummary: "We love our closet!",
    content:
      "We were having a difficult time finding someone to complete our master bedroom closet until I called Classy Closets and spoke with Hannah. I am so grateful the other two options didn't work out, because working with Hannah has been the highlight of our home's construction experiences.",
     },
  {
    id: 2,
    name: "Julie H",
    date: "8/22/2022",
    location: "Gilbert",
    shortSummary: "Good Design For Our Space",
    content:
      "Our master bedroom closet is on the small side. Heather listened to our needs and created a design that gives more hanging space for clothes, space for shoes , a set of drawers that gives us more storage and a tidy, no clutter appearance...  Thank you!",
  },
  {
    id: 3,
    name: "Nicole S",
    date: "8/12/2022",
    location: "Chandler",
    shortSummary: "Beautiful Bedroom Closets",
    content:
      "These are the most beautiful closets I have had installed. Cheyenne Holt has incredible taste and created amazing closets for us",
  },
  {
    id: 4,
    name: "Paul M",
    date: "8/12/2022",
    location: "Venice CA",
    shortSummary: "Thank you for a great job!",
    content:
      "We had Classy Closets create new cabinets for our garage. It turned out very nice and we are so pleased with how easy it was to work with Hannah in the design and how seamless the installation went.",
  },
  {
    id: 5,
    name: "Heather C",
    date: "5/6/2022",
    location: "Carlsbad CA",
    shortSummary: "Service with smiles",
    content:
      "Classy Closets has great customer service - leaving our residents extremely happy. Kellin, Heather, Derrick, and Levi are quite the team. ",
  },
  {
    id: 6,
    name: "Dan C",
    date: "12/26/2018",
    location: "Gilbert",
    shortSummary: "Amazing work at my house",
    content:
      "Thank you again for the amazing work you and your team did at my house. I’ve dealt with many contractors and never have I had an experience like the one I had with you. You guys made sure that I knew exactly when you would be there, were right on time, did an incredible job.",
  },
  {
    id: 7,
    name: "Angie W",
    date: "9/13/2017",
    location: "Surprise, AZ",
    shortSummary: "Excellent everything",
    content:
      "We enjoyed working with the Classy Closets crew, along with Hannah and Emily. The design was fabulous and the installers were great!! We can highly recommend them.",
  },
]
