import React from "react";
import { Container } from "./styled";

import ResponsiveImage from "../../../../components/Responsive/Image";

const ImgBanner = ({ image }) => {
  const _image = image || {
    mobile: {
      alt: "corner desk with white accents",
      src: "/images/countertops/reviews-background-mobile.jpg",
      layout: "responsive",
      loading: "lazy",
      quality: 60,
      width: "390",
      height: "534",
    },
    desktop: {
      alt: "corner desk with white accents",
      src: "/images/countertops/reviews-background.jpg",
      layout: "responsive",
      loading: "lazy",
      quality: 60,
      width: "1920",
      height: "628",
    },
  };
  return (
    <Container>
      <ResponsiveImage {..._image} />
    </Container>
  );
};

export default ImgBanner;
