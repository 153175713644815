import styled, { css } from "styled-components";

const Spacer = styled.div`
  ${({ theme, direction, size, sizeMobile, hideMobile }) => css`
    width: ${direction === "horizontal"
      ? theme.spacings[size] || theme.spacings.spacing_m
      : "auto"};
    height: ${direction === "horizontal"
      ? "auto"
      : theme.spacings[size] || theme.spacings.spacing_m};
    @media only screen and (max-width: 768px) {
      width: ${direction === "horizontal"
      ? theme.spacings[sizeMobile] ||
      theme.spacings[size] ||
      theme.spacings.spacing_m
      : "auto"};
      height: ${direction === "horizontal"
      ? "auto"
      : theme.spacings[sizeMobile] ||
      theme.spacings[size] ||
      theme.spacings.spacing_m};
      display: ${hideMobile ? "none" : "block"};
    }
  `}
`;

export default Spacer;
