import styled from "styled-components";

export const Container = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  padding-bottom: 74px;
  & .slick-slider {
    .slick-dots {
      li > button {
        &::before {
          font-size: 16px;
        }
      }
    }
  }

  @media only screen and (max-width: 560px) {
    margin-top: -30px;
    padding-bottom: 40px;
  }
`;
