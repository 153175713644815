import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;

  @media only screen and (max-width: 560px) {
    height: 254px;
  }
`;

export const SummaryWrap = styled.div`
  position: absolute;
  bottom: 83px;
  left: ${({ theme }) => theme.spacings.spacing_big};

  @media only screen and (max-width: 768px) {
    left: ${({ theme }) => theme.spacings.spacing_xxl_m};
  }
  @media only screen and (max-width: 560px) {
    bottom: 35px;
  }
`;

export const SummaryFirst = styled.div`
  position: relative;
  width: fit-content;
`;

export const SummaryText = styled.div`
  font-family: "Lora Medium";
  font-size: 40px;
  color: ${({ theme }) => theme.colors.blackGreen};
  margin-top: -50px;
  padding-left: 6px;
  padding-right: 12px;

  @media only screen and (max-width: 768px) {
    font-size: 32px;
    margin-top: -39px;
  }
  @media only screen and (max-width: 560px) {
    font-size: 30px;
    margin-top: -37px;
  }
`;

export const BgColorDiv = styled.div`
  height: 28px;
  width: 100%;
  background-color: #ffffffaa;
  width: 100%;

  @media only screen and (max-width: 768px) {
    height: 22px;
  }
  @media only screen and (max-width: 560px) {
    height: 18px;
  }
`;
