import React from "react";
import Slider from "react-slick";
import Review from "./Review";
import { Container } from "./styled";
import reviewsData from "../../../../data/reviewsData";

const ReviewsSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Container>
      <Slider {...settings}>
        {reviewsData.map((review, key) => (
          <div key={key}>
            <Review
              name={review.name}
              shortSummary={review.shortSummary}
              content={review.content}
            />
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default ReviewsSlider;
