import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Wrapper,
  CustomerName,
  ShortSummary,
  Content,
} from "./styled";

const Review = ({ name, shortSummary, content }) => {
  const [show, setShowMore] = React.useState(false)
  const showMore = () => {
    setShowMore(prev => !prev)
  }


  return (
    <Container>
      <Wrapper onClick={showMore} $size={show}>
        <CustomerName>{name}</CustomerName>
        <ShortSummary>{shortSummary}</ShortSummary>
        <Content >"{content}"</Content>
      </Wrapper>
    </Container>
  );
};

Review.propTypes = {
  name: PropTypes.string,
  shortSummary: PropTypes.string,
  content: PropTypes.string,
};

export default Review;
