import React from "react";
import Typography from "../../../components/Typography";
import { useWindowDimensions } from "../../../hooks";
import ImgBanner from "./ImgBanner";
import ReviewsSlider from "./ReviewsSlider";
import {
  Container,
  SliderContainer,
  SliderContent,
  TitleWrapper,
} from "./styled";

const Reviews = ({ image }) => {
  const { isSmallDevice } = useWindowDimensions();
  return (
    <Container>
      <ImgBanner image={image} />
      <SliderContainer>
        <SliderContent>
          <TitleWrapper>
            <Typography
              fontType="header"
              fontWeight="bold"
              fontSize={!isSmallDevice ? "3rem" : "1.5rem"}
              textAlign="center"
            >
              What our customers are saying:
            </Typography>
          </TitleWrapper>
          <ReviewsSlider />
        </SliderContent>
      </SliderContainer>
    </Container>
  );
};

export default Reviews;
